import { makeStyles } from '@material-ui/core/styles';

export const FooterStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(2),
    textTransform: 'none',
    fontSize: '13px',
  },
  wrapperTable: {
    padding: '10px',
    "& table": {
      borderCollapse: 'collapse',
      border: '1px solid #E8E8E8',
      width: '100%',
      lineHeight: '37px',
      textAlign: 'center',
      fontSize: '14px',
      "& caption": {
        backgroundColor: '#F2F2F2',
        border: '1px solid #E8E8E8',
        fontWeight: 'bold'
      },
      "& th": {
        border: 'solid 1px #e8e8e8'
      },
      "& td": {
        border: '1px solid #E8E8E8'
      }
    }
  },
  paragraph: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #E8E8E8',
    fontWeight: 'bold',
    color: '#fb0f1c',
  }

}));
