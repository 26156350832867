import React, {useState} from 'react';
import {Grid} from '@material-ui/core';

import useStyles from './ScheduleManagementStyles';
import Schedule from '../../components/schedule/Schedule';
import AppCalendar from "../../helpers/AppCalendar";


const ScheduleManagement = () => {

  const classes = useStyles();

  const [year, setYear] = useState(AppCalendar.getMowYear() ?? new Date().getFullYear());
  const [weekRegular, setWeekRegular] = useState(0);
  const [updateWeeks, setUpdateWeeks] = useState([]);

  return (
    <div>
      <ScheduleManagementContext.Provider value={{
        year,
        setYear,
        weekRegular,
        setWeekRegular,
        updateWeeks,
        setUpdateWeeks
      }}>
        <div className={classes.wrapperGeneral}>
          <h3>Global Schedule Management</h3>
          <Grid container className={classes.wrapperContainerCalendar}>
            <Grid item md={12}>
              <Schedule/>
            </Grid>
          </Grid>
        </div>
      </ScheduleManagementContext.Provider>
    </div>
  )
}

export default ScheduleManagement;
export const ScheduleManagementContext = React.createContext();
