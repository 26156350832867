import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import { Form, Formik } from 'formik';
import {
  isEmpty as _isEmpty, isUndefined as _isUndefined, toNumber as _toNumber,
} from 'lodash';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  AppBar, Box, FormControlLabel, Grid,
  makeStyles, Tab, Tabs, Typography,
} from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import AddIcon from '@material-ui/icons/Add';

import { useUI } from '../../app/context/ui';
import { addCustomer } from '../../app/store/mm/customerSlice';
import { removeNotification } from '../../app/store/mm/notificationSlice';
import Calendar from '../../components/calendar/Calendar';
import { Button } from '../../components/shared/MaterialUI';
import convertDate from '../../helpers/ConvertDate';
import customerForm from '../../models/customerForm';
import { ActivityService } from '../../services';
import CustomerService from '../../services/CustomerService';
import AppCalendar from '../../helpers/AppCalendar';
import DeleteCustomer from './DeleteCustomer';
import Step from './wizardEdit/Step';
import Step1 from './wizardEdit/Step1';
import Step2 from './wizardEdit/Step2';
import Step3 from './wizardEdit/Step3';
import Step4 from './wizardEdit/Step4';
import Step5 from './wizardEdit/Step5';
import Step6 from './wizardEdit/Step6';
import Tap1 from './wizardGeneral/Step1';
import Tap3 from './wizardGeneral/Step3';
import Tap5 from './wizardGeneral/Step5';
import Tap6 from './wizardGeneral/Step6';
import Tap7 from './wizardGeneral/Step7';
import Tap8 from './wizardGeneral/Step8';
import Tap9 from './wizardGeneral/Step9';
import ScrollCustomer from './ScrollCustomer';
import NoteModal from '../../components/note/crud/NoteModal';
import RequestMessage from './RequestMessage';
import Step2ReadOnly from './wizardGeneral/Step2ReadOnly';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ctnCalendar: {
    marginLeft: '20px',
    width: '96%',
  },
  btnDeleteCustomer: {
    margin: '0px',
    '& .MuiTypography-body1': {
      fontSize: '15px',
    },
    '& .Mui-checked': {
      color: 'red !important',
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#f48c8c !important',
    },
  },
  btnDelete: {
    marginLeft: '19px',
    backgroundColor: '#c33636',
  },
  wrapperTap1:{
    '& .MuiBox-root':{
      padding: '0px !important',
      '& .MuiDataGrid-root': {
        boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
      }

    }
  }
}));

const { formId, formField } = customerForm;

const EditCustomer = (props) => {

  const { mm, user/*notification */ } = props;
  const parentId = !_isEmpty(mm.parentIds) ? mm.parentIds[0] : '';
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [taps, setTaps] = useState(0);
  const [bagrass,setBagrass] = useState(false);
  const [updateServiceNote, setUpdateServiceNote] = useState('');

  const [previous, setPrevious] = useState({});
  const [year, setYear] = useState(AppCalendar.getMowYear() ?? new Date().getFullYear());

  const history = useHistory();
  const formikRef = useRef();
  const dispatch = useDispatch();
  const { blockUI } = useUI();

  const query = new URLSearchParams(useLocation().search);
  const key = _toNumber(query.get('key')) || 0;
  const tap = _toNumber(query.get('tap')) || 0;

  const location = useLocation();
  const typeLog = location.state?.typeLog || false;
  const [locationState, setLocationState] = useState([]);
  const typeLookup = history?.location?.state?.type;

  const [openModal, setOpenModal] = useState(false);

  if (_isUndefined(location.state)) {
    history.goBack();
  }

  useEffect(() => {
    setLocationState(location.state);
  }, [location.state]);

  const customerService = new CustomerService();
  const activityService = new ActivityService();
  const formInitialValues = useSelector(({ mm }) => mm.customer.options);

  const newOrder = _isUndefined(formInitialValues.statusOrders)
    ? false
    : formInitialValues.statusOrders !== 1;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    insertUrlParam('key', newValue);
  };

  const handleChangeTaps = (event, newValue) => {
    setTaps(newValue);
    insertUrlParam('tap', newValue);
  };

  const handleSubmit = useCallback(() => {
    try {
      // Submit
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const getReadCustomer = async (
    id = locationState.id ? locationState.id : formInitialValues.id
  ) => {
    try {
      if (!_isUndefined(id)) {
        customerService.getAccessToken();
        const r1 = await customerService.read(id);
        dispatch(addCustomer({ ...r1.data }));
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  };

  const updatedNewOrder = async (status) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const id = locationState.id || history?.location?.state?.id;
      let statusOrders = status ? 1 : 2;
      const isRestart = !status;
      await customerService.update({ statusOrders, isRestart }, id);
      history.push('new-orders', { approved: status });
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  };

  const onAfterSend = async () => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const id = locationState.id;
      const statusOrders = 2;
      const isRestart = true;
      await customerService.update({ statusOrders, isRestart }, id);
      history.push('new-orders');
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  }

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    history.push({ search: searchParams.toString(), state: { id: locationState.id } });
  };

  const [checkRequestDeletedCustomer, setCheckRequestDeletedCustomer] = useState(false);

  const handleRequestDeleted = async (e) => {
    try {
      blockUI.current.open(true);
      activityService.getAccessToken();
      const date = convertDate();
      setCheckRequestDeletedCustomer(e.target.checked);
      if (e.target.checked) {
        let activity = {
          type_id: 3,
          customer_attributes_id: mm.id,
          customerName: mm.fullName,
          name: `Customer ${mm.fullName} has a profile removal request`,
          date,
          available: true,
        };
        await activityService.create(activity);
        dispatch(removeNotification());
      }
      blockUI.current.open(false);
    } catch (e) {
      blockUI.current.open(false);
    }
  };

  useEffect(() => {
    const id = locationState.id ? locationState.id : formInitialValues.id;
    if (!_isUndefined(id)) {
      (async function init() {
        await getReadCustomer(id);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikRef]);

  useEffect(() => {
    if (key !== value) {
      setValue(key);
    }
    if (tap !== taps) {
      setTaps(tap);
    }
  }, [key, tap, value, taps]);

  useEffect(() => {
    setCheckRequestDeletedCustomer(false);
  }, [mm.id]);

  return (
    <div>
      <PreviousCustomerContext.Provider value={{
        previous,
        setPrevious,
        bagrass,
        setBagrass,
        year,
        setYear,
        setUpdateServiceNote,
        updateServiceNote,
      }}>
      <Grid container direction='row' justifyContent='space-between' alignItems='center'>
        <Grid item xs={12} sm={3}>
          {
            (mm.statusOrders !== 0)
              &&
                <FormControlLabel
                  className={classes.btnDeleteCustomer}
                  control={
                    <Switch checked={checkRequestDeletedCustomer} onChange={handleRequestDeleted} />
                  }
                  label={checkRequestDeletedCustomer ? 'Deletion requested!' : 'Request deletion?'}
                />
          }
        </Grid>
        <Grid item xs={12} sm={1}/>
        <Grid item xs={12} sm={3}>
          <ScrollCustomer typeLog={typeLog} typeLookup={typeLookup}/>
        </Grid>
        <Grid item xs={12} sm={5} style={{ textAlign: 'center' }}>
          {!newOrder ? (
            <>
              <Button
                variant='contained'
                color='primary'
                size='small'
                startIcon={<AddIcon />}
                onClick={() => {
                  history.push('create-customer');
                }}
              >
                Create customer
              </Button>
              <DeleteCustomer />
            </>
          ) : (
            user?.role == 'Representative' ? ("") : (
              <>
              <Box style={{ textAlign: 'center' }}>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ marginRight: '5px' }}
                  onClick={() => {
                    updatedNewOrder(true);
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ backgroundColor: 'white', color: 'black' }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Reject
                </Button>
              </Box>
              </>
              )
            )
          }
        </Grid>
      </Grid>
      <br />
      <div className={classes.root}>
        <Formik
          innerRef={formikRef}
          initialValues={formInitialValues}
          // validationSchema = {currentValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {() => (
            <Form id={formId}>
              <Grid container direction='column'>
                <Grid container>
                  <Grid item xs={7}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Step
                          formField={formField}
                          formikRef={formikRef}
                          newOrder={newOrder}
                          globe={`https://earth.google.com/web/search/${mm.serviceAddress}`}
                          parentId={parentId}
                          setLocationState={setLocationState}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AppBar position='static' color='inherit'>
                          <Tabs
                            value={value}
                            onChange={handleChange}
                            variant='scrollable'
                            scrollButtons='on'
                            indicatorColor='primary'
                            textColor='primary'
                            aria-label='scrollable force tabs example'
                          >
                            <Tab
                              label='Call Notes'
                              {...a11yProps(0)}
                              style={{ textTransform: 'none' }}
                            />
                            <Tab
                              label='Service Information'
                              {...a11yProps(1)}
                              style={{ textTransform: 'none' }}
                            />
                            <Tab
                              label='Other locations'
                              {...a11yProps(2)}
                              style={{ textTransform: 'none' }}
                            />
                            <Tab
                              label='Property information'
                              {...a11yProps(3)}
                              style={{ textTransform: 'none' }}
                            />
                            <Tab
                              label='Billing info'
                              {...a11yProps(4)}
                              style={{ textTransform: 'none' }}
                            />
                            <Tab
                              label='Special request'
                              {...a11yProps(5)}
                              style={{ textTransform: 'none' }}
                            />
                          </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} className={classes.wrapperTap1}>
                          <Tap1 formField={formField} formikRef={formikRef} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                          <Step1 formField={formField} formikRef={formikRef} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                          <Step2
                            formField={formField}
                            formikRef={formikRef}
                            setLocationState={setLocationState}
                          />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                          <Step3 formField={formField} formikRef={formikRef} />
                        </TabPanel>
                        <TabPanel value={value} index={4}>
                          <Step4 formField={formField} formikRef={formikRef} />
                        </TabPanel>
                        <TabPanel value={value} index={5}>
                          <Step6 formField={formField} formikRef={formikRef} />
                        </TabPanel>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <AppBar position='static' color='inherit' className={classes.ctnCalendar}>
                      <Calendar />
                    </AppBar>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <AppBar position='static' color='inherit' style={{ marginTop: '1.5em' }} >
                    <Tabs
                      value={taps}
                      onChange={handleChangeTaps}
                      variant='scrollable'
                      scrollButtons='on'
                      indicatorColor='primary'
                      textColor='primary'
                      aria-label='scrollable force tabs example'
                    >
                      <Tab label='Private note' {...a11yProps(0)} style={{ textTransform: 'none' }} />
                      <Tab
                        label='Service Notes'
                        {...a11yProps(1)}
                        style={{ textTransform: 'none' }}
                      />
                      <Tab label='Completed services' {...a11yProps(2)} style={{ textTransform: 'none' }} />
                      <Tab
                        label='Billing notes'
                        {...a11yProps(3)}
                        style={{ textTransform: 'none' }}
                      />
                      <Tab label='History' {...a11yProps(4)} style={{ textTransform: 'none' }} />
                      <Tab label='B&B' {...a11yProps(5)} style={{ textTransform: 'none' }} />
                      <Tab label='Spanish' {...a11yProps(6)} style={{ textTransform: 'none' }} />
                      <Tab label='Marketing' {...a11yProps(7)} style={{ textTransform: 'none' }} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={taps} index={0}>
                    <Step5 formikRef={formikRef} />
                  </TabPanel>
                  <TabPanel value={taps} index={1}>
                    <Step2ReadOnly formField={formField} formikRef={formikRef} />
                    {/* <Tap2 formField={formField} formikRef={formikRef} /> */}
                  </TabPanel>
                  <TabPanel value={taps} index={2}>
                    <Tap9 formField={formField} formikRef={formikRef} />
                  </TabPanel>
                  <TabPanel value={taps} index={3}>
                    <Tap5 formField={formField} formikRef={formikRef} />
                  </TabPanel>
                  <TabPanel value={taps} index={4}>
                    <Tap3 formField={formField} formikRef={formikRef} />
                  </TabPanel>
                  <TabPanel value={taps} index={5}>
                    <Tap6 formField={formField} formikRef={formikRef} mm={mm}/>
                  </TabPanel>
                  <TabPanel value={taps} index={6}>
                    <Tap7 formField={formField} formikRef={formikRef} />
                  </TabPanel>
                  <TabPanel value={taps} index={7}>
                    <Tap8 formField={formField} formikRef={formikRef} />
                  </TabPanel>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </div>
      <NoteModal
        open={openModal}
        setOpen={setOpenModal}
        type={'rejected_note'}
        onAfterSend={() => {onAfterSend('OK', 'The customer has been rejected.')}}
      />
      <Grid item xs={12} sm={12} style={{textAlign:'center'}}>
        <ScrollCustomer typeLog={typeLog} typeLookup={typeLookup}/>
      </Grid>
    </PreviousCustomerContext.Provider>
      <RequestMessage id={mm.id}/>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    mm: state.mm.customer.options,
    notification: state.mm.notification.options,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditCustomer);

export const PreviousCustomerContext = React.createContext();
